import { API_URL } from "../utils/client";

export const updateTask = async (task_id, title, description, rewards, link, type) => {

    const response = await fetch(`${API_URL}/updateTask/${task_id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            title: title,
            description: description,
            rewards: rewards,
            link: link,
            type: type
        }),
    });

    if (!response.ok) {
        throw new Error("Tasks not added");
    }

    return await response.json();
};