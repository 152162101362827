import React from "react";

import { DownOutlined, UserOutlined } from "@ant-design/icons";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import vectorIcon from "../../assets/vectoricon.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Button1 from "../../components/Button1";
import filterIcon from "../../assets/filterIcon.svg";
import { useNavigate } from "react-router-dom";
import "./header.css";
import zIndex from "@mui/material/styles/zIndex";
// const handleButtonClick = (e) => {
//   message.info('Click on left button.');
//   console.log('click left button', e);
// };

const handleMenuClick = (e) => {
  // message.info('Click on menu item.');
  // console.log('click', e);
};

const items = [
  {
    label: "1st menu item",
    key: "1",
    icon: <UserOutlined />,
  },
];

const menuProps = {
  items,
  onClick: handleMenuClick,
};

const Header = () => {
  const goTo = useNavigate();
  return (
    <div
      // style={{paddingTop:'30px'}}
      className="header"
    >
      <h2
        className="header-h1"
        style={{
          fontFamily: "Montserrat-Bold",
          fontSize: "24px",
          fontWeight: "700",
          color: "#FFFFFF",
        }}
      >
        Rankings
      </h2>
      {/* <div style={{ flexGrow: "1" }}></div> */}
      {/* <Dropdown menu={menuProps} shape="round" onClick={(e)=>{}}> */}
      {/* <Button
        className="button-week"
        style={{
          borderRadius: "60px",
          marginRight: "10px",
          height: "45px",
          width: "131px",
          background:
            "linear-gradient(#090909, #090909) padding-box, linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%) border-box",
          border: "1px solid transparent",
        }}
        onClick={(e) => {}}
      >
        <Space
          style={{
            fontFamily: "Montserrat",
            color: "#FFFFFF",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          This Week
          <img
            src={vectorIcon}
            alt="icon"
            style={{ width: "16px", height: "14px", paddingTop: "8px" }}
          />
        </Space>
      </Button> */}
      {/* <Dropdown className="button-week">
      <Dropdown.Toggle className="dropdown-toggle" id="dropdown-basic">
        This Week
        <FontAwesomeIcon icon={faChevronDown} className="dropdown-icon" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
      <img className="image-week" src={filterIcon} alt="" />

      {/* </Dropdown> */}
      {/* <Button1
        action={() => {
          goTo("/edit_task");
        }} */}
      
    </div>
  );
};

export default Header;