import { API_URL } from "../utils/client";

export const deleteTasks = async (task_id) => {
    try {
        const response = await fetch(`${API_URL}/deleteTask/${task_id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        return await response.json();
    } catch (error) {
        console.error("Failed to delete task group:", error);
        throw error;
    }
};
