import React, { useContext } from 'react';
import { Button, ConfigProvider, Space } from 'antd';
import vector1 from '../../assets/Vector.svg'
import AddIcon from '@mui/icons-material/Add';
import './index1.css';

const Button2 = ({ action }) => {
  const { getPrefixCls } = useContext(ConfigProvider.ConfigContext);
  const rootPrefixCls = getPrefixCls();
  
  return (
    <ConfigProvider>
      <Space>

        <Button 
          type="primary"
          size="large"
          onClick={action}
          // className={`linear-gradient-button ${rootPrefixCls}-btn-primary`}

          className='topbutton-header1 '
        >
          <AddIcon className="icon-button"color='white'/>
        <p className='button-text-1'> Add Group </p>
        </Button>
      </Space>
    </ConfigProvider>
  );
};

export default Button2;
