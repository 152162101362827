import { API_URL } from "../utils/client";

export const deleteTaskGroups = async (id) => {
    const response = await fetch(`${API_URL}/deleteTaskGroup/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    return await response.json();
};
