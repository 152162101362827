import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Snackbar, Alert } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import img from "../../assets/mark1.png";
import editIcon from "../../assets/edit-svgrepo-com 1.svg";
import deleteicon from "../../assets/bin-with-lid 1.svg";
import "./index.css";
import { toast } from 'react-toastify';

import Header from "./header";
import DeleteModal from "../../components/DeleteQuiz/index.js";
import Loading from "../../components/loading";

import { displayNumbers } from "../../lib/tools";

import { getTasks, deleteTask } from "../../apis/tasks.js";
import { getQuiz } from "../../apis/getQuiz.js";
import { deleteQuiz } from "../../apis/deleteQuiz.js";
const TaskList = [
  {
    img: "taskIcons/Play_Store.png",
    title: "PlayStore",
    description: "Download the Bluemoon App",
    completetion: 15,
    rewards: 100000,
    started_Date: "1/11/2023",
    ended_Date: "24/11/2023",
    id: "34234234234",
    type: 0,
  },
];

const TaskPage = () => {
  const goTo = useNavigate();

  const [selectedTaskButton, setSelectedTaskButton] = useState(0);
  const [selectedLive, setSelectedLive] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [taskList, setTaskList] = useState([{}]);

  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [quiz, setQuiz] = useState();
  const handleNewTaskButtonClick = () => {
    setSelectedTaskButton(0);
  };

  const handleEssentialTaskButtonClick = () => {
    setSelectedTaskButton(1);
  };

  const handleBonusTaskButtonClick = () => {
    setSelectedTaskButton(2);
  };

  const handleLiveButtonClick = () => {
    setSelectedLive(0);
  };

  const handleEndedButtonClick = () => {
    setSelectedLive(1);
  };

 const handledeleteTask = async (id) => {
  setQuiz((prevTasks) => prevTasks.filter((item) => item.quiz._id !== id));

  try {
    await deleteQuiz(id);
    toast.success(`Quiz deleted successfully`); 
    console.log(`Quiz with id ${id} deleted successfully`);
  } catch (error) {
    toast.error('Error deleting Quiz'); 
    console.error('Error deleting Quiz:', error);
  }
};

  const handleDeleteButtonClick = (itemId) => {
    setOpenDeleteModal(true);
    setCurrentItem(itemId);
  };

  const deleteItem = (itemId) => {
    handledeleteTask(itemId);
  };

  const handleEditButtonClick = (itemId) => {
    console.log("itemId", itemId);
    goTo(`/edit_quiz/${itemId}`);
  };


  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const data = await getQuiz();
        setQuiz(data);

        console.log("Quiz Data:", data);
      } catch (error) {
        console.error("Error fetching quiz data:", error);
      }
    };
    fetchQuiz();
  }, []);

  return (
    // <Layout>
    <>
      {/* <Loading disp={isLoading}/> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setIsOpenSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setIsOpenSnackbar(false);
          }}
          severity={snackbarStatus}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        deleteCurrentItem={() => deleteItem(currentItem)}
      />
      <div className="landing-contain-content">
        <Header />
        <div className="landing-contain-section">
          <TableContainer
            component={Paper}
            style={{
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              backgroundColor: "rgba(0,0,0,1)",
              boxShadow: "none",
              borderRadius: "10px",
            }}
          >
            <Table
              className="for-border"
              sx={{ minWidth: 650, backgroundColor: "#101214" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  {/* <TableCell  style={{fontFamily:'Montserrat-Medium',color:'#D7D8D9',fontWeight:'500',fontSize:'14px',lineHeight:'15px',borderBottom: '1px solid #24262B'}}>Group Image</TableCell> */}
                  <TableCell
                    style={{
                      fontFamily: "Montserrat-Medium",
                      color: "#D7D8D9",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "15px",
                      borderBottom: "1px solid #24262B",
                    }}
                  >
                    Quiz type
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat-Medium",
                      color: "#D7D8D9",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "15px",
                      borderBottom: "1px solid #24262B",
                    }}
                  >
                    Correct Answers
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat-Medium",
                      color: "#D7D8D9",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "15x",
                      borderBottom: "1px solid #24262B",
                    }}
                  >
                    Wrong Answers
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat-Medium",
                      color: "#D7D8D9",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "15px",
                      borderBottom: "1px solid #24262B",
                    }}
                  >
                    Date Started
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat-Medium",
                      color: "#D7D8D9",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "15px",
                      borderBottom: "1px solid #24262B",
                    }}
                  >
                    Date Ended
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Montserrat-Medium",
                      color: "#D7D8D9",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "15px",
                      borderBottom: "1px solid #24262B",
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {quiz?.map((task, key) => (
                  <TableRow key={key}>
                    <TableCell
                      className="tablecell-container"
                      style={{
                        fontFamily: "Montserrat-Bold",
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#FFFFFF",
                        lineHeight: "21px",
                      }}
                    >
                      {task?.quiz?.type}
                    </TableCell>
                    <TableCell
                      className="tablecell-container"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#D7D8D9",
                        lineHeight: "21px",
                      }}
                    >
                      {task?.correctCount}
                    </TableCell>
                    <TableCell
                      className="tablecell-container"
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#D7d8d9",
                        lineHeight: "21px",
                      }}
                    >
                      {displayNumbers(task?.incorrectCount)}
                    </TableCell>
                    <TableCell
                      className="tablecell-container"
                      style={{
                        fontFamily: "Montserrat-Medium",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#D7D8D9",
                        lineHeight: "21px",
                      }}
                    >
                      {task?.quiz?.date}
                    </TableCell>
                    <TableCell
                      className="tablecell-container"
                      style={{
                        fontFamily: "Montserrat-Medium",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#D7D8D9",
                        lineHeight: "21px",
                      }}
                    >
                      {selectedLive === 1 ? task.ended_Date : ""}
                    </TableCell>
                    <TableCell
                      className="tablecell-container"
                      style={{ padding: "2px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          position: "relative",
                          right: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleEditButtonClick(task?.quiz?._id);
                          }}
                        >
                          <img
                            src={editIcon}
                            alt="edit"
                            style={{ width: "22px", height: "22px" }}
                          />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleDeleteButtonClick(task?.quiz?._id);
                            console.log(task?.quiz?._id);
                          }}
                        >
                          <img
                            src={deleteicon}
                            alt="delete"
                            style={{ width: "22px", height: "22px" }}
                          />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>

    // </Layout>
  );
};

export default TaskPage;
