import { API_URL } from "../utils/client";

export const addTask = async (title, description,rewards,link,type,img,taskGoup) => {
    const response = await fetch(`${API_URL}/addTask`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
           title: title,
           description: description,
           rewards:rewards,
           link:link,
           type:type,
           img:img,
           taskGoup: taskGoup
        }),
    });

    if (!response.ok) {
        throw new Error("Tasks not added");
    }

    return await response.json(); 
};