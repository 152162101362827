import { API_URL } from "../utils/client";

export const deleteQuiz= async (id) => {
    const response = await fetch(`${API_URL}/deleteQuiz/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        throw new Error("Network response was not ok");
    }

    return await response.json();
};
