import { API_URL } from "../utils/client";

export const addQuiz = async (type,question,options,correctAnswer,reward,bot) => {
    const response = await fetch(`${API_URL}/addQuiz`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type,
          question,
          options,
          correctAnswer,
          reward,
          bot
        }),
    });

    if (!response.ok) {
        throw new Error("Quiz not added");
    }

    return await response.json(); 
};