import { API_URL } from "../utils/client";

export const updateTaskGroup = async (id, taskGroupName, taskDescription) => {
    const response = await fetch(`${API_URL}/updateTaskGroup/${id}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            taskGroupName,
            taskDescription,
        }),
    });

    if (!response.ok) {
        throw new Error("Task group not updated");
    }

    return await response.json();
};
