import { API_URL } from "../utils/client";

export const addTaskGroup = async (title, description) => {
    const response = await fetch(`${API_URL}/addTaskGroup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
           title: title,
           description: description
        }),
    });

    if (!response.ok) {
        throw new Error("Task group not added");
    }

    return await response.json(); 
};