import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Card, Button } from "antd";
import earnBright from "../../assets/earnBright.svg";
import quizIcon from "../../assets/quizicon.svg";
import rankingicon from "../../assets/rankingicon.svg";
import rankingBright from "../../assets/rankingbright.svg"
import QuizBright from "../../assets/quizbright.svg"
import radeemIcon from "../../assets/radeemIcon.svg"
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import CachedRoundedIcon from "@mui/icons-material/CachedRounded";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import HeadphonesRoundedIcon from "@mui/icons-material/HeadphonesRounded";
import ShoppingBasketRoundedIcon from "@mui/icons-material/ShoppingBasketRounded";
import CardGiftcardRoundedIcon from "@mui/icons-material/CardGiftcardRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Mark1 from "../../assets/mark1.png";
import Mark2 from "../../assets/mark2.png";
import './index.css'
import Person from "../../assets/admin.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import dummy from "../../assets/closeIcon.svg";
import statemanager from "./Statemanager";
import rightIcon from "../../assets/right-icon.svg";

import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Dropdown, message, Space, Tooltip } from "antd";
import vectorIcon from "../../assets/vectoricon.svg";
import Button1 from "../../components/Button1";
import filterIcon from "../../assets/filterIcon.svg";
import { useLocation } from "react-router-dom";


var sample = null;
const drawerWidth = 245;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const Navbar = ({ children }) => {
  const [selectedKey, setSelectedKey] = useState("");
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);

  // Toggle dropdown visibility
  const toggleDropdown = (key) => {
    setOpenDropdown(openDropdown === key ? null : key);
  };

  const items = [
    {
      key: "dashboard",
      icon: (
        <GridViewRoundedIcon
          style={{ color: "rgba(244, 246, 247, 0.5)", height: '24px', width: '24px' }}
        />
      ),
      label: (
        <span
          style={{
            fontFamily: "Montserrat-Medium",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "16px",
            color: "#9ea0a5",
            display: 'flex',
            alignItems: 'center', // Align icon and text
            gap: '8px' // Add gap between text and dropdown icon
          }}
        >
          Dashboard
          
        </span>
      ),
    },
    {
      key: "earn",
      icon: (
        <div>
          {[
            "tasks",
            "tasks-group",
            "app-reward",
            "streak-reward",
            "land-marks",
          ].includes(selectedKey) ? (
            <img src={earnBright} alt=".." />
          ) : (
            <WorkspacePremiumRoundedIcon
              style={{ color: "rgba(244, 246, 247, 0.5)", height: '24px', width: '24px' }}
            />
          )}
        </div>
      ),
      label: (
        <span
          style={{
            fontFamily: "Montserrat-Medium",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "16px",
            color: "#9ea0a5",
            display: 'flex',
            alignItems: 'center', // Align icon and text
            gap: '8px' // Add gap between text and dropdown icon
          }}
          onClick={() => toggleDropdown("earn")}
        >
          Earn
          <FontAwesomeIcon
          icon={openDropdown === "earn" ? faCaretUp : faCaretDown}
          className="dropdown-icon-nav"
          style={{
            color: openDropdown === "earn" ? "rgba(244,246,247)" : "rgba(244, 246, 247, 0.5)",
          }} // Change color conditionally
        />
      </span>
    ),
      children: [
        {
          key: "task",
          label: (
            <span
              style={{
                fontFamily: "Montserrat-Medium",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "18.75px",
                color: "#9ea0a5",
                display: 'flex',
                alignItems: 'center', // Align icon and text
                gap: '8px' // Add gap between text and dropdown icon
              }}
            >
              Tasks
             
            </span>
          ),
        },
        {
          key: "tasks-group",
          label: (
            <span
              style={{
                fontFamily: "Montserrat-Medium",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "18.75px",
                color: "#9ea0a5",
                display: 'flex',
                alignItems: 'center', // Align icon and text
                gap: '8px' // Add gap between text and dropdown icon
              }}
            >
              Tasks Group 
            </span>
          ),
        },
        
        

      ],
      
    },
    {
      key: "quiz",
      icon: (
        <div>
          {[
            "quiz",
           
          ].includes(selectedKey) ? (
            <img src={QuizBright} alt=".." />
          ) : (
            <img src={quizIcon}
              style={{ color: "rgba(244, 246, 247, 0.5)", height: '24px', width: '24px' }}
            />
          )}
        </div>
      ),
      label: (
       
        <span 
          style={{
            fontFamily: "Montserrat-Medium",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "16px",
            color: "#9ea0a5",
            display: 'flex',
            alignItems: 'center', // Align icon and text
            gap: '8px' // Add gap between text and dropdown icon
          }}
        >
         Quiz
        
        </span>
        
      ),
    },
    {
      key: "ranking",
        icon: (
          <div>
            {[
              "ranking",
             
            ].includes(selectedKey) ? (
              <img src={rankingBright} alt=".." />
            ) : (
              <img src={rankingicon}
                style={{ color: "rgba(244, 246, 247, 0.5)", height: '24px', width: '24px' }}
              />
            )}
          </div>
        ),
      label: (
        <span
          style={{
            fontFamily: "Montserrat-Medium",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "16px",
            color: "#9ea0a5",
            display: 'flex',
            alignItems: 'center', // Align icon and text
            gap: '8px' // Add gap between text and dropdown icon
          }}
        >
         Ranking
          
        </span>
      ),
    },
    
  ];
  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };
  const levelKeys = getLevelKeys(items);

  const goTo = useNavigate();

  const [stateOpenKeys, setStateOpenKeys] = useState(["2", "23"]);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogOutButtonClick = () => {
    localStorage.removeItem("token");
    goTo("/");
    window.location.reload();
  };

  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );
    // open
    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          // remove repeat key
          .filter((_, index) => index !== repeatIndex)
          // remove current level all child
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      // close
      setStateOpenKeys(openKeys);
    }
  };

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);

    if (e.key === "tasks") goTo("/task");
    else if (e.key === "tasks-group") goTo("/taskgroup");
    else if (e.key=== "quiz") goTo("/quiz")
      else if (e.key=== "ranking") goTo("/ranking")
    else goTo("/task")
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: "transparent",
          boxShadow: "none",
          minHeight: "100px",
          justifyContent: "center",
          zIndex:"0",
        }}
      >
        <Toolbar>
          <IconButton className="button-nav"
            // color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              mr: 2,
              // mt: 3.7,
              ...(open && { display: "none" }),
              // color: "white",
            
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap component="div"> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            className="header"
          >
            {/* <p  style={{fontFamily:"Montserrat",fontWeight:"700",fontSize:"24px",lineHeight:"29.26px",color:"#ffffff",margin:"0px"}}>{getHeaderText()}</p> */}
            {/* <div style={{ flexGrow: "1" }}></div> */}
            {/* <Dropdown menu={menuProps} shape="round" onClick={(e)=>{}}> */}
            {/* <div style={{ display: "flex", alignItems: "center" }} >
              <Button
                className="button-week"
                style={{
                  borderRadius: "60px",
                  marginRight: "10px",
                  height: "45px",
                  width: "131px",
                  background:
                    "linear-gradient(#090909, #090909) padding-box, linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%) border-box",
                  border: "1px solid transparent",
                }}
                onClick={(e) => { }}
              >
                <Space
                  style={{
                    fontFamily: "Montserrat",
                    color: "#FFFFFF",
                    fontWeight: "600",
                    fontSize: "14px",
                  }}
                >
                  This Week
                  <img
                    src={vectorIcon}
                    alt="icon"
                    style={{
                      width: "16px",
                      height: "14px",
                      paddingTop: "8px",
                    }}
                  />
                </Space>
              </Button>

              <img className="image-week" src={filterIcon} alt="" />

              </Dropdown>
              <Button1
                action={() => {
                  goTo("/addTask");
                }}
              />
            </div> */}
          </div>
          {/* </Typography> */}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "1px solid #121212",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {/* <DrawerHeader>
          <img src="/Images/logo.svg" alt="" style={{width:"162.32px"}} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon/>
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader> */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              background: "#101214",
              boxSizing: "border-box",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader
            style={{
              display: "flex",
              justifyContent: "space-between",
              // padding: "0px 16px 0px 24px",
            }}
          >
            <img
              src="/Images/logo.svg"
              alt=""
              style={{
                paddingTop: "15px",
                paddingBottom: "36px",
                paddingLeft: "73px",
                
              }}
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon style={{ fill: "rgba(244, 245, 247, 0.5)",marginTop:'-20px' }} />
              ) : (
                <ChevronRightIcon
                  style={{ fill: "rgba(244, 245, 247, 0.5)" }}
                />
              )}
            </IconButton>
          </DrawerHeader>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
              paddingBottom: "30px",
            }}
          >
            <List>
              <Menu
                mode="inline"
                // selectedKeys={[selectedKey]}
                selectedKeys={[selectedKey]}
                onClick={handleMenuClick}
                openKeys={stateOpenKeys}
                onOpenChange={onOpenChange}
                style={{
                  width: 256,
                  minHeight: "calc(100% - 114px)",
                  borderBottom: "0px",
                  color: "transparent",
                  background: "#101214",
                }}
                iconSize={120}
                items={items}
                horizontalItemHoverColor={"#00B80C1A"}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: "rgba(255,255,255,0.5)",
                  },
                }}
              />
            </List>
            <Card
              bordered={false}
              style={{
                borderRadius: "0px",
                borderTop: "none",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#101214",
              }}
            >
              <div className="admin-avartar">
                <img src={Person} alt="" />
                <div style={{ marginLeft: "5px" }}>
                  <h5 className="selected-text">John Wick</h5>
                  <span>Admin</span>
                </div>
                <div style={{ flexGrow: "1" }}></div>
                <img
                  src={rightIcon}
                  alt="icon"
                  style={{ width: "26px", height: "24px", background: "none" }}
                />
              </div>
              <Button
                type="primary"
                shape="round"
                icon={<img src="/Images/logout.svg" alt="/" />}
                onClick={handleLogOutButtonClick}
                size={"large"}
         
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Montserrat-bold",
                  color:'#FFFFFF',
                  background:"rgba(201,0,0,1)",
                  // background:
                  //   "linear-gradient(#090909, #090909) padding-box, linear-gradient(92.23deg, #FF00D9 -19.69%, #6C4EFE 100%) border-box",
                  border: "1px solid transparent",
                  height: "52px",
                  width: "205px",
                  borderRadius:'16px'
                }}
              >
                Log out
              </Button>
            </Card>
          </div>
        </Drawer>

        {/* <Divider /> */}
        <List>
          {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))} */}
          <Menu
            mode="inline"
            openKeys={stateOpenKeys}
            onOpenChange={onOpenChange}
            style={{
              width: 256,
              minHeight: "calc(100% - 114px)",
              borderBottom: "0px",
              color: "transparent",
            }}
            iconSize={120}
            items={items}
            // itemColor = {"rgba(255,255,255,0.5) !important"}
            horizontalItemHoverColor={"#9a9aa9"}
            onClick={(item) => {
              if (item.key === "tasks") goTo("/task");
              else goTo("/empty");
            }}
            sx={{
              "& .MuiSvgIcon-root": {
                color: "rgba(255,255,255,0.5)", // Apply color to all icons
              },
              "& .selected-text": {
                background: "linear-gradient(90deg, #0B6719 0%, #2AD72D 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              },
            }}
          />
        </List>
        <Card
          bordered={false}
          style={{
            // height:"58px",
            borderRadius: "0px",
            borderTop: "none",
            display: "flex",
            backgroundColor: "black",
            //justifyContent: "center",

            // padding:"10px"
          }}
        >
          <div className="admin-avartar">
            <img src={Person} alt="" />
            <div>
              <h5>John Jack</h5>
              <span>Admin</span>
            </div>
            <div style={{ flexGrow: "1" }}></div>
            <ChevronRightIcon
              sx={{
                color: "#D7D8D9", 
              }}
            />
          </div>
          <Button className="button-logout"
            type="primary"
            shape="round"
            icon={<img src="/Images/logout.svg" alt="/" />}
            onClick={handleLogOutButtonClick}
            size={"large"}
            danger
            style={{
              fontSize: "16px",
              fontWeight: "700",
              fontFamily: "Montserrat",
            }}
          >
            Log Out
          </Button>
        </Card>
      </Drawer>
      <Main
        open={open}
        sx={{
          backgroundColor: "#101214",
          height: "100%",
          paddingLeft: "20px",
          paddingTop: "18px",
          // Apply media query with '@media'
          "@media (max-width: 1070px)": {
            display: "none",
          },
        }}
      >
        {/* <DrawerHeader /> */}
        {children}
      </Main>
    </Box>
  );
};
export default Navbar;
