import { API_URL } from "../utils/client";

export const getMissions = async () => {
    const response = await fetch(`${API_URL}/getMission`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
       
    });

    if (!response.ok) {
        throw new Error("response was not ok");
    }

    return await response.json(); 
};