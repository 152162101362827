import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { DropzoneArea } from "material-ui-dropzone";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import axios from "axios";
import config from "../../config";
import { addTaskGroup } from "../../apis/addTaskGroup";
import Loading from "../../components/loading";
import { toast } from 'react-toastify';

import { addNewTask, updateTask } from "../../apis/tasks";

import "./index.css";

const EditTaskPage = () => {
  const goTo = useNavigate();

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [rewards, setRewards] = useState(0);
  const [link, setLink] = useState("");
  const [type, setType] = useState(0);
  const [img, setImg] = useState("");
  const [taskId, setTaskId] = useState("");
  const [imageSource, setImageSource] = useState();

  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const handleAddTaskGroup = async () => {
    if (!title || !description) {
      toast.error("All fields are required"); 
    }
  
    try {
      const data = await addTaskGroup(title, description);
      console.log(data, "this is data");
  
      toast.success("Task group added successfully!");
    } catch (error) {
      console.error("Error message:", error);
  
      toast.error("Failed to add task group."); 
    }
  };
  

  const handleCloseSnackbar = () => {
    setIsOpenSnackbar(false);
  };
const handleCancelButtonClick = () => {
  goTo("/taskgroup");
};


  return (
    <>
    {isLoading ? (
        <div className="loading-container">
            <div className="spinner"></div>
        </div>
    ) : (
        <>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={isOpenSnackbar}
                autoHideDuration={6000}
                onClose={() => {
                    setIsOpenSnackbar(false);
                }}
            >
                <Alert
                    onClose={() => {
                        setIsOpenSnackbar(false);
                    }}
                    severity={snackbarStatus}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            
            <div style={{ backgroundColor: "#101214" }}>
                <div className="edit-task-page">
                    <div className="edit-task-page-header">
                        <h2
                            style={{
                                fontFamily: "Montserrat-Bold",
                                fontSize: "24px",
                                fontWeight: "700",
                                color: "#F4F5F7",
                                lineHeight: "29.05px",
                            }}
                        >
                            Reward /
                        </h2>
                        <h4
                            style={{
                                fontFamily: "Montserrat-Medium",
                                fontSize: "16px",
                                fontWeight: 500,
                                color: "#9ea0a5",
                                marginLeft: "5px",
                                marginTop: "5px",
                            }}
                        >
                            Add Group
                        </h4>
                    </div>
                    <div
                        style={{
                            border: "2px solid #121212",
                            borderRadius: "10px",
                            marginTop: "24px",
                            height: "100vh",
                        }}
                    >
                        <div className="edit-task-page-body">
                            <div className="edit-task-page-body-left">
                                <div style={{ marginBottom: "30px" }}>
                                    <label
                                        style={{
                                            fontFamily: "Montserrat-Bold",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            color: "#FFFFFF",
                                            lineHeight: "19.5px",
                                        }}
                                        htmlFor="task_title"
                                    >
                                        Group Name
                                    </label>
                                    <input
                                        type="text"
                                        className="task_title"
                                        value={title}
                                        onChange={(e) => {
                                            setTitle(e.target.value);
                                        }}
                                        name="task_title"
                                        placeholder="Enter group name"
                                    />
                                </div>
                                <div>
                                    <label
                                        style={{
                                            fontFamily: "Montserrat-Bold",
                                            fontSize: "16px",
                                            fontWeight: "700",
                                            color: "#FFFFFF",
                                            lineHeight: "19.5px",
                                        }}
                                        htmlFor="task_description"
                                    >
                                        Group Description
                                    </label>
                                    <textarea
                                        className="task_title1"
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                        name="task_description"
                                        placeholder="Enter group description"
                                    />
                                    <style>
                                        {`#task_description::placeholder {
                                            color: rgba(244, 245, 247, 0.5);
                                        }`}
                                    </style>
                                </div>
                                <div style={{ marginBottom: "33px" }}></div>
                                <div style={{ marginBottom: "36px" }}></div>
                                <div style={{ marginBottom: "60px" }}></div>
                                <br />
                                <div className="edit-page-left-button-group_1">
                                    <Button
                                        variant="contained"
                                        onClick={handleAddTaskGroup}
                                        disableElevation
                                    >
                                        {taskId.length ? "Publish" : "Publish"}
                                    </Button>
                                    <Button onClick={handleCancelButtonClick} variant="outlined">
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                            <div className="edit-task-page-body-right">
                                <label
                                    style={{
                                        fontFamily: "Montserrat-Bold",
                                        fontSize: "16px",
                                        fontWeight: "700",
                                        color: "#FFFFFF",
                                    }}
                                    htmlFor="upload_image"
                                >
                                    Upload Task Image
                                </label>
                                <label className="imagesize">Image size: 52x52</label>
                                <DropzoneArea
                                    id="upload_image"
                                    filesLimit={1}
                                    acceptedFiles={["image/*"]}
                                    maxFileSize={5000000}
                                    showAlerts={["error", "info"]}
                                    dropzoneText="Drag & Drop or "
                                    value={img}
                                    onChange={(files) => {
                                        setImageSource(files[0]);
                                    }}
                                    onDropRejected={(files) => {
                                        alert("File rejected: " + files[0].name);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )}
</>

  );
};

export default EditTaskPage;
